import {useEffect} from 'react';
import {useScroll} from 'framer-motion';
import debounce from 'lodash.debounce';

type InfiniteHorizontalScrollOptions =
  | Partial<{
      onScrollEnd: () => void;
      threshold: number;
      debounceDelay: number;
      condition: boolean;
    }>
  | undefined;

export const useInfiniteHorizontalScroll = (
  scrollContainerRef: React.RefObject<HTMLDivElement>,
  {onScrollEnd, threshold = 0.9, debounceDelay = 150, condition = true}: InfiniteHorizontalScrollOptions = {},
) => {
  const {scrollXProgress} = useScroll({container: scrollContainerRef});

  useEffect(() => {
    const debouncedOnScrollProgress = debounce((scrollXProgress: number) => {
      if (scrollXProgress < threshold) return;

      if (!condition) return;

      onScrollEnd?.();
    }, debounceDelay);

    const unsubscribe = scrollXProgress.onChange(debouncedOnScrollProgress);

    return () => {
      unsubscribe();
      debouncedOnScrollProgress.cancel();
    };
  }, [scrollXProgress, onScrollEnd, threshold, debounceDelay, condition]);
};
