import {useCallback, useEffect, useRef, useState} from 'react';

export enum Period {
  second = 1000,
  minute = 1000 * 60,
  hour = 1000 * 60 * 60,
  day = 1000 * 60 * 60 * 24,
}

type Params = {
  to: Date | number; // Can be entered as either a date or a number in seconds
  from?: Date;
  period?: Period;
  enabled?: boolean;
  isPaused?: boolean;
  onCompleted?: () => void;
};

export function useCountdown({
  to,
  from = new Date(),
  period = Period.second,
  enabled = true,
  isPaused = false,
  onCompleted,
}: Params) {
  const initialSeconds = typeof to === 'number' ? to : 0;
  const [time, setTime] = useState({days: 0, hours: 0, minutes: 0, seconds: initialSeconds, isExpired: false});
  const counterID = useRef<NodeJS.Timer>();
  const _to = typeof to === 'number' ? +new Date() + to * Period.second : +to; // Get date in milliseconds
  const count = useRef(_to - +from);

  const calculateTime = useCallback(() => {
    if (isPaused) {
      clearInterval(counterID.current!);
      return;
    }

    if (!enabled || count.current <= Period.second) {
      clearInterval(counterID.current!);

      setTime({days: 0, hours: 0, minutes: 0, seconds: 0, isExpired: true});
      onCompleted?.();
      return;
    }

    count.current -= Period.second;
    const days = Math.floor(count.current / Period.day);
    const hours = Math.floor((count.current % Period.day) / Period.hour);
    const minutes = Math.floor((count.current % Period.hour) / Period.minute);
    const seconds = Math.floor((count.current % Period.minute) / Period.second);

    setTime({days, hours, minutes, seconds, isExpired: false});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isPaused, onCompleted]);

  useEffect(() => {
    counterID.current = setInterval(calculateTime, period);

    return () => clearInterval(counterID.current!);
  }, [period, calculateTime]);

  const resetCountdown = (seconds: number) => {
    clearInterval(counterID.current!);
    count.current = seconds * Period.second;
    counterID.current = setInterval(calculateTime, period);
  };

  return {...time, resetCountdown};
}
