import {api} from '@eksab/api';
import {useQuery} from '@eksab/hooks';
import {getCountry, getLanguage} from '@eksab/util';
import {QueryFunctionContext, QueryKey} from '@tanstack/react-query';

export interface League {
  id: number;
  name: string;
  upcoming_matches: MatchT[];
}

const queryKey = ['leagues'];

const queryFn = (context: QueryFunctionContext<QueryKey>) => {
  const locale = context.queryKey[context.queryKey.length - 1] as Locale;
  return api
    .get('/v2/leagues', {headers: {'X-Locale': getLanguage(locale), 'X-REGION': getCountry(locale)}})
    .then((res) => res.data.data);
};

export const useLeaguesQuery = () => useQuery<League[]>(queryKey, queryFn);

useLeaguesQuery.queryKey = queryKey;
useLeaguesQuery.queryFn = queryFn;
