import {SyntheticEvent, useCallback, useEffect} from 'react';

interface Params {
  action: () => void;
  condition: boolean;
  threshold?: number;
}

export function useInfiniteScroll({action, condition, threshold = 1}: Params) {
  const onScroll = useCallback(
    (e: SyntheticEvent<Document>) => {
      const {scrollHeight, scrollTop, clientHeight} = e.currentTarget.scrollingElement!;

      if (condition && (scrollHeight - scrollTop) * threshold < clientHeight) action();
    },
    [action, condition, threshold],
  );

  useEffect(() => {
    document.addEventListener('scroll', onScroll as any);

    return () => document.removeEventListener('scroll', onScroll as any);
  }, [action, condition, threshold, onScroll]);
}
