import {RefObject, useEffect, useState} from 'react';

interface ObserverOptions extends IntersectionObserverInit {
  triggerOnce?: boolean;
}

export function useOnScreen(ref: RefObject<HTMLDivElement>, options?: ObserverOptions) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);

      if (options?.triggerOnce && entry.isIntersecting && entry.intersectionRatio === 1)
        observer.unobserve(entry.target);
    }, options);

    ref.current && observer.observe(ref.current);

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return isIntersecting;
}
