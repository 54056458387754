import {useEffect, useRef, useState} from 'react';
import {useRouter} from 'next/router';
import {useDebounce} from 'use-debounce';

interface RouterFilterProps<T> {
  default: T;
  showDefault?: boolean;
  name?: string;
  wait?: number;
}

export function useRouterFilter<T>({
  default: defaultValue,
  showDefault = false,
  name = 'filter',
  wait = 0,
}: RouterFilterProps<T>) {
  const {push, query} = useRouter();
  const isFirstRender = useRef(true);

  const currentQueryValue = (query[name] as unknown as T) ?? defaultValue;

  const [activeFilter, setActiveFilter] = useState(currentQueryValue);
  const [debouncedFilter] = useDebounce(activeFilter, wait);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const updateURLParam = (filter: T) => {
      const {[name]: _, ...prevQueryWithoutFilter} = query;

      push(
        {
          pathname: undefined,
          query: {
            ...prevQueryWithoutFilter,
            ...((filter !== defaultValue || showDefault) && {[name]: `${filter}`}),
          },
        },
        undefined,
        {shallow: true},
      );
    };

    updateURLParam(debouncedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter, name, defaultValue, showDefault]);

  useEffect(() => {
    setActiveFilter(currentQueryValue);
  }, [currentQueryValue]);

  return [activeFilter, setActiveFilter, wait ? debouncedFilter : undefined] as const;
}
